<template>
  <div v-if="!data">Loading Please wait...
  </div>
  <div v-else>
    <b-form>

      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :items="data"
        primary-key="MittelID"
        :fields="fields"
      >
        <template v-slot:cell(Mittel)="row">
          <span
            v-b-tooltip.html="{ variant: 'secondary' }"
            :title="row.item.Info"
          >{{ row.item.Mittel }}</span>
        </template>
        <template v-slot:cell(WertK1)="row">
          <b-form-input v-facade="'#######'" :formatter="formatNumber" v-model="row.item.WertK1" class="text-right"
            @blur="applyFormat($event, row, 'WertK1')"
            @focus="removeFormat"
          />
        </template>
        <template v-slot:cell(WertK2)="row">
          <b-form-input
            v-model="row.item.WertK2" v-facade="'#######'" :formatter="formatNumber" class="text-right"
              @blur="applyFormat($event, row, 'WertK2')"
              @focus="removeFormat"
            />
        </template>
        <template v-slot:cell(WertK3)="row">
          <b-form-input
            v-model="row.item.WertK3" v-facade="'#######'" :formatter="formatNumber" class="text-right"
              @blur="applyFormat($event, row, 'WertK3')"
              @focus="removeFormat"
            />
        </template>
        <template v-slot:cell(WertK4)="row">
          <b-form-input
            v-model="row.item.WertK4" v-facade="'#######'" :formatter="formatNumber" class="text-right"
              @blur="applyFormat($event, row, 'WertK4')"
              @focus="removeFormat"
            />
        </template>
        <template v-slot:cell(WertK5)="row">
          <b-form-input v-facade="'#######'" :formatter="formatNumber" v-model="row.item.WertK5" class="text-right"
            @blur="applyFormat($event, row, 'WertK5')"
            @focus="removeFormat"
          />
        </template>
        <template v-slot:cell(WertK6)="row">
          <b-form-input v-facade="'#######'" :formatter="formatNumber" v-model="row.item.WertK6" class="text-right"
            @blur="applyFormat($event, row, 'WertK6')"
            @focus="removeFormat"
          />
        </template>
      </b-table>

      <!-- <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Bemerkung Mittel Frau"
              label-for="BemFrau"
            >
              <b-form-textarea
                id="BemFrau"
                v-model="data[0].BemMittelEF"
                rows="2"
                max-rows="4"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Bemerkung Mittel Mann"
              label-for="BemMann"
            >
              <b-form-textarea
                id="BemMann"
                v-model="data[0].BemMittelEM"
                rows="2"
                max-rows="4"
              />
            </b-form-group>
          </b-col>
        </b-row> -->

    </b-form>
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="save_changes(true)"
    >
      Speichern
    </b-button>
    <b-button
        variant="secondary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="fetchData()"
      >
        Abbrechen
      </b-button>
  </div>
</template>

<script>
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import { required } from '@validations'

import {
  // BTable, BButton, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BForm,
  BTable, BButton, BFormInput, BForm, VBTooltip,
} from 'bootstrap-vue'
import db from '@/service/mittelbedarf'

export default {
  components: {
    BTable,
    BButton,
    // BRow,
    // BCol,
    // BFormGroup,
    BFormInput,
    // BFormTextarea,
    BForm,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      data: null,
      isEditing: false,
      perPage: 40,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        { key: 'Mittel', label: 'Mittel', sortable: true },
        { key: 'WertK1', label: 'Kind 1', thClass: 'text-right' }, // eslint-disable-line
        { key: 'WertK2', label: 'Kind 2', thClass: 'text-right' }, // eslint-disable-line
        { key: 'WertK3', label: 'Kind 3', thClass: 'text-right' }, // eslint-disable-line
        { key: 'WertK4', label: 'Kind 4', thClass: 'text-right' }, // eslint-disable-line
        { key: 'WertK5', label: 'Kind 5', thClass: 'text-right' }, // eslint-disable-line
        { key: 'WertK6', label: 'Kind 6', thClass: 'text-right' }, // eslint-disable-line
      ],
    }
  },
  async mounted() {
    this.$root.$refs.MITTELKIND = this
  },
  async created() {
    await this.fetchData()
    if (this.data.length > 0) {
      this.totalRows = this.data.length
      this.fields[1].label = this.data[0].VornameK1
      this.fields[2].label = this.data[0].VornameK2
      this.fields[3].label = this.data[0].VornameK3
      this.fields[4].label = this.data[0].VornameK4
      this.fields[5].label = this.data[0].VornameK5
      this.fields[6].label = this.data[0].VornameK6
      if (this.data[0].VornameK6 === null) this.fields.splice(6, 1)
      if (this.data[0].VornameK5 === null) this.fields.splice(5, 1)
      if (this.data[0].VornameK4 === null) this.fields.splice(4, 1)
      if (this.data[0].VornameK3 === null) this.fields.splice(3, 1)
      if (this.data[0].VornameK2 === null) this.fields.splice(2, 1)
    }
  },
  methods: {
    async fetchData() {
      this.data = null
      const r = await db.getMittelKind(this.$root.currDosID)
      if (r.data.length > 0) this.data = r.data
    },
    formatNumber(value) {
      if (!this.isEditing && typeof value === 'string') {
        value = value.replace(/\B(?=(\d{3})+(?!\d))/g, "'") // eslint-disable-line
        return value
      }
      return false
    },
    applyFormat(event, row, value) { // eslint-disable-line
      event.target.value = (event.target.value || '0').replace(/^0+(?!$)/, '').replace(/\B(?=(\d{3})+(?!\d))/g, "'") // eslint-disable-line
      let ix = 0
      for (; ix < this.data.length; ix++) if (this.data[ix].MittelID === row.item.MittelID) break // eslint-disable-line
      this.data[ix][value] = event.target.value
      this.isEditing = false
    },
    removeFormat(event) {
      this.isEditing = true
      event.target.value = event.target.value.replace(/[^\d]/g, '') // eslint-disable-line
      if (event.target.value === '0') event.target.value = '' // eslint-disable-line
    },
    async save_changes(showToast) {
      await db.putMittelKind(this.data)

      if (showToast) this.$root.showToast('Speichern', 'Mittel-Daten Kind erfolgreich gespeichert!')
    },
  },
}
</script>
