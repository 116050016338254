<template>
  <div v-if="!data">Loading Please wait...
  </div>
  <div v-else>
    <b-form>

      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :items="data"
        primary-key="MittelID"
        :fields="fields"
      >
        <template v-slot:cell(Mittel)="row">
          <span
            v-b-tooltip.html="{ variant: 'secondary' }"
            :title="row.item.Info"
          >{{ row.item.Mittel }}</span>
        </template>
        <template v-slot:cell(WertEF)="row">
          <b-form-input v-facade="'########'" :formatter="formatNumber" v-model="row.item.WertEF" class="text-right"
            @blur="applyFormat($event, row, 'WertEF')"
            @focus="removeFormat"
          />
        </template>
        <template v-slot:cell(WertEM)="row">
          <b-form-input v-facade="'########'" :formatter="formatNumber" v-model="row.item.WertEM" class="text-right"
            @blur="applyFormat($event, row, 'WertEM')"
            @focus="removeFormat"
          />
        </template>
      </b-table>

      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            :label="'Bemerkung Mittel ' + $root.VNameFrau"
            label-for="BemFrau"
          >
            <b-form-textarea
              id="BemFrau"
              v-model="data[0].BemMittelEF"
              rows="2"
              max-rows="4"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
          :label="'Bemerkung Mittel ' + $root.VNameMann"
            label-for="BemMann"
          >
            <b-form-textarea
              id="BemMann"
              v-model="data[0].BemMittelEM"
              rows="2"
              max-rows="4"
            />
          </b-form-group>
        </b-col>
      </b-row>

    </b-form>
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="save_changes(true)"
    >
      Speichern
    </b-button>
    <b-button
        variant="secondary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="fetchData()"
      >
        Abbrechen
      </b-button>
  </div>
</template>

<script>
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import { required } from '@validations'

import {
  BTable, BButton, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BForm, VBTooltip,
} from 'bootstrap-vue'
import db from '@/service/mittelbedarf'

export default {
  components: {
    BTable,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    // ValidationProvider,
    // ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      data: null,
      perPage: 40,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        { key: 'Mittel', label: 'Mittel', sortable: true },
        { key: 'WertEF', label: this.$root.VNameFrau, thClass: 'text-right' }, // eslint-disable-line
        { key: 'WertEM', label: this.$root.VNameMann, thClass: 'text-right' }, // eslint-disable-line
      ],
      /* eslint-disable global-require */
    }
  },
  async mounted() {
    this.$root.$refs.MITTELPAAR = this
    window.saveFormData = this.save_changes

    await this.fetchData()
  },

  methods: {
    async fetchData() {
      this.data = null
      const r = await db.getMittelPaar(this.$root.currDosID)
      if (r.data.length > 0) {
        this.data = r.data
        this.totalRows = this.data.length
      }
    },
    formatNumber(value) {
      if (!this.isEditing && typeof value === 'string') {
        value = value.replace(/\B(?=(\d{3})+(?!\d))/g, "'") // eslint-disable-line
        return value
      }
      return false
    },
    applyFormat(event, row, value) { // eslint-disable-line
      event.target.value = (event.target.value || '0').replace(/^0+(?!$)/, '').replace(/\B(?=(\d{3})+(?!\d))/g, "'") // eslint-disable-line
      let ix = 0
      for (; ix < this.data.length; ix++) if (this.data[ix].MittelID === row.item.MittelID) break // eslint-disable-line
      this.data[ix][value] = event.target.value
      this.isEditing = false
    },
    removeFormat(event) {
      this.isEditing = true
      event.target.value = event.target.value.replace(/[^\d]/g, '') // eslint-disable-line
      if (event.target.value === '0') event.target.value = '' // eslint-disable-line
    },
    async save_changes(showToast) {
      await db.putMittelPaar(this.data)
      if (this.data !== null) await db.putMittelPaarBem(this.$root.currDosID, this.data[0].BemMittelEM, this.data[0].BemMittelEF)

      if (showToast) this.$root.showToast('Speichern', 'Mittel-Daten vom Paar erfolgreich gespeichert!')
    },
  },
}
</script>
